<template>
  <delivery-zone-form @submit="create" />
</template>
<script>
import { mapState, mapActions } from 'vuex'
import navigationMixin from '@/mixins/navigation'
import DeliveryZoneForm from '@/components/DeliveryZone/DeliveryZoneForm'

export default {
  mixins: [navigationMixin],
  components: {
    DeliveryZoneForm
  },
  computed: {
    ...mapState('shared', ['error'])
  },
  methods: {
    ...mapActions('delivery', ['createDeliveryZone']),
    ...mapActions('notification', ['showNotification']),
    async create (deliveryZone) {
      await this.createDeliveryZone(deliveryZone)
      if (this.error) {
        this.showNotification({ message: this.error.message, color: 'var(--app-popup-bg-color-danger)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
        return
      }

      this.showNotification({ message: 'Zona de envió creada exitosamente.', color: 'var(--app-popup-bg-color-success)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
      this.goToRouteByName('delivery-zones')
    }
  }
}
</script>
